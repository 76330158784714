import React from 'react';
import { string, shape } from 'prop-types';
import {
  Heading,
  Button,
  defaultTheme,
  Image,
} from '@nintendo-of-america/component-library';

import s from './PromoGridItem.module.css';

/** A promo display component to be used within a product grid */
const PromoGridItem = ({ asset, cta, heading }) => {
  return (
    <a className={s.root} href={cta?.url}>
      <div className={s.wrapper}>
        <Image
          assetPath={asset}
          crop="pad"
          backgroundFillColor={defaultTheme.color.black}
          className={s.styledImage}
          alt=""
        />
        <div className={s.content}>
          <Heading variant="h3">{heading}</Heading>
          {cta && (
            <Button still buttonWidth="full">
              {cta.label}
            </Button>
          )}
        </div>
      </div>
    </a>
  );
};

PromoGridItem.propTypes = {
  asset: string,
  cta: shape({
    url: string,
    label: string,
  }),
  heading: string,
};

export default PromoGridItem;
