import React, { useCallback, useRef, useMemo } from 'react';
import { arrayOf, shape, string, func, bool } from 'prop-types';
import { useRouter } from 'next/router';
import { useLocalizer } from '@nintendo-of-america/react-hooks';
import { NCOM_ORIGIN } from '@local/lib/constants';
import * as S from './ActiveCollectionSticky.styles';

const ActiveCollectionSticky = (props) => {
  const { isOpen, pillFarm, onToggle } = props;

  const { asPath } = useRouter();
  const pillContainerRef = useRef();

  const { text } = useLocalizer();

  const handleTogglePillFarm = useCallback(() => {
    if (onToggle) {
      onToggle(pillContainerRef.current.clientHeight);
    }
  }, [onToggle]);

  const { activePill, remainingPills } = useMemo(() => {
    const url = new URL(asPath, NCOM_ORIGIN);
    return pillFarm.reduce(
      (acc, pill) => {
        if (url.pathname === pill.url) {
          return {
            ...acc,
            activePill: { ...pill },
          };
        }

        return {
          ...acc,
          remainingPills: [...acc.remainingPills, { ...pill }],
        };
      },
      {
        activePill: null,
        remainingPills: [],
      }
    );
  }, [pillFarm, asPath]);

  return (
    <S.Container>
      <S.Wrapper>
        <S.ActiveControlContainer>
          <S.ActivePill round size={'small'} variant={'tertiary'}>
            <S.Label>{activePill?.label}</S.Label>
          </S.ActivePill>
          <S.Toggle
            round
            size={'small'}
            onClick={handleTogglePillFarm}
            aria-label={text(isOpen ? 'See less' : 'See all')}
          >
            <S.IconSpacer />
            <S.ChevronToggleIcon $isOpen={isOpen} size={20} />
          </S.Toggle>
        </S.ActiveControlContainer>
        <S.PillContainer ref={pillContainerRef}>
          {remainingPills.map((pill) => {
            return (
              <S.ButtonContainer key={pill.label}>
                <S.Pill
                  round
                  onClick={handleTogglePillFarm}
                  href={pill.url}
                  size={'small'}
                  variant={'quaternary'}
                  proxyLinkProps={{
                    scroll: false,
                  }}
                >
                  <S.Label>{pill.label}</S.Label>
                </S.Pill>
              </S.ButtonContainer>
            );
          })}
        </S.PillContainer>
      </S.Wrapper>
    </S.Container>
  );
};

ActiveCollectionSticky.defaultProps = {
  pillFarm: [],
};

ActiveCollectionSticky.protoTypes = {
  isOpen: bool,
  pillFarm: arrayOf(
    shape({
      label: string,
      url: string,
    })
  ),
  onToggle: func,
};

export default ActiveCollectionSticky;
